module.exports = {
  GET_CHAT_ROOMS: "GET_CHAT_ROOMS", //Currently unused
  GET_CHANNELS: "GET_CHANNELS",
  SEND_ROBOT_SERVER_INFO: "SEND_ROBOT_SERVER_INFO",
  AUTHENTICATE: "AUTHENTICATE",
  VALIDATED: "VALIDATED",
  ROBOT_SERVER_UPDATED: "ROBOT_SERVER_UPDATED",
  GET_CHAT: "GET_CHAT",
  SEND_CHAT: "SEND_CHAT",
  ACTIVE_USERS_UPDATED: "ACTIVE_USERS_UPDATED",
  MESSAGE_SENT: "MESSAGE_SENT",
  MESSAGE_RECEIVED: "MESSAGE_RECEIVED",
  TIMEOUT: "TIMEOUT",
  GLOBAL_TIMEOUT: "GLOBAL_TIMEOUT",
  HEARTBEAT: "HEARTBEAT",
  USER_STATUS_UPDATED: "USER_STATUS_UPDATED",
  BUTTON_COMMAND: "BUTTON_COMMAND",
  CHANNELS_UPDATED: "CHANNELS_UPDATED",
  JOIN_CHANNEL: "JOIN_CHANNEL",
  GET_ROBOTS: "GET_ROBOTS",
  USER_CONNECTED: "USER_CONNECTED"
};
