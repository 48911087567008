//Some of these icons were from: https://icomoon.io/app/#/select
//In order to use this, pair it with /src/components/common/icon.jsx to use as a react component
//Useful tool for merging SVG paths: https://jakearchibald.github.io/svgomg/

const ICONS = {
  BUBBLE:
    "M512 64c282.77 0 512 186.25 512 416 0 229.752-229.23 416-512 416-27.156 0-53.81-1.734-79.824-5.044-109.978 109.978-241.25 129.7-368.176 132.596v-26.916c68.536-33.578 128-94.74 128-164.636 0-9.754-0.758-19.33-2.164-28.696-115.796-76.264-189.836-192.754-189.836-323.304 0-229.75 229.23-416 512-416z",
  ENLARGE2:
    "M1024 0v416l-160-160-192 192-96-96 192-192-160-160zM448 672l-192 192 160 160h-416v-416l160 160 192-192z",
  SHRINK2:
    "M448 576v416l-160-160-192 192-96-96 192-192-160-160zM1024 96l-192 192 160 160h-416v-416l160 160 192-192z",
  BIN2:
    "M192 1024h640l64-704h-768zM640 128v-128h-256v128h-320v192l64-64h768l64 64v-192h-320zM576 128h-128v-64h128v64z",
  CLOUD_CHECK:
    "M892.268 514.49c2.442-11.108 3.732-22.646 3.732-34.49 0-88.366-71.634-160-160-160-14.224 0-28.014 1.868-41.134 5.352-24.796-77.352-97.288-133.352-182.866-133.352-87.348 0-161.054 58.336-184.326 138.17-22.742-6.62-46.792-10.17-71.674-10.17-141.384 0-256 114.616-256 256 0 141.382 114.616 256 256 256h608c88.366 0 160-71.632 160-160 0-78.718-56.854-144.16-131.732-157.51zM416 768l-160-160 64-64 96 96 224-224 64 64-288 288z",
  BOLD:
    "M707.88 484.652c37.498-44.542 60.12-102.008 60.12-164.652 0-141.16-114.842-256-256-256h-320v896h384c141.158 0 256-114.842 256-256 0-92.956-49.798-174.496-124.12-219.348zM384 192h101.5c55.968 0 101.5 57.42 101.5 128s-45.532 128-101.5 128h-101.5v-256zM543 832h-159v-256h159c58.45 0 106 57.42 106 128s-47.55 128-106 128z",
  UNDERLINE:
    "M704 64h128v416c0 159.058-143.268 288-320 288-176.73 0-320-128.942-320-288v-416h128v416c0 40.166 18.238 78.704 51.354 108.506 36.896 33.204 86.846 51.494 140.646 51.494s103.75-18.29 140.646-51.494c33.116-29.802 51.354-68.34 51.354-108.506v-416zM192 832h640v128h-640z",
  ITALIC: "M896 64v64h-128l-320 768h128v64h-448v-64h128l320-768h-128v-64z",
  PARAGRAPH_LEFT:
    "M0 64h1024v128h-1024zM0 256h640v128h-640zM0 640h640v128h-640zM0 448h1024v128h-1024zM0 832h1024v128h-1024z",
  PARAGRAPH_CENTER:
    "M0 64h1024v128h-1024zM192 256h640v128h-640zM192 640h640v128h-640zM0 448h1024v128h-1024zM0 832h1024v128h-1024z",
  PARAGRAPH_RIGHT:
    "M0 64h1024v128h-1024zM384 256h640v128h-640zM384 640h640v128h-640zM0 448h1024v128h-1024zM0 832h1024v128h-1024z",
  GOOGLE:
    "M522.2 438.8v175.6h290.4c-11.8 75.4-87.8 220.8-290.4 220.8-174.8 0-317.4-144.8-317.4-323.2s142.6-323.2 317.4-323.2c99.4 0 166 42.4 204 79l139-133.8c-89.2-83.6-204.8-134-343-134-283 0-512 229-512 512s229 512 512 512c295.4 0 491.6-207.8 491.6-500.2 0-33.6-3.6-59.2-8-84.8l-483.6-0.2z",
  FACEBOOK:
    "M608 192h160v-192h-160c-123.514 0-224 100.486-224 224v96h-128v192h128v512h192v-512h160l32-192h-192v-96c0-17.346 14.654-32 32-32z",
  TWITTER:
    "M1024 226.4c-37.6 16.8-78.2 28-120.6 33 43.4-26 76.6-67.2 92.4-116.2-40.6 24-85.6 41.6-133.4 51-38.4-40.8-93-66.2-153.4-66.2-116 0-210 94-210 210 0 16.4 1.8 32.4 5.4 47.8-174.6-8.8-329.4-92.4-433-219.6-18 31-28.4 67.2-28.4 105.6 0 72.8 37 137.2 93.4 174.8-34.4-1-66.8-10.6-95.2-26.2 0 0.8 0 1.8 0 2.6 0 101.8 72.4 186.8 168.6 206-17.6 4.8-36.2 7.4-55.4 7.4-13.6 0-26.6-1.4-39.6-3.8 26.8 83.4 104.4 144.2 196.2 146-72 56.4-162.4 90-261 90-17 0-33.6-1-50.2-3 93.2 59.8 203.6 94.4 322.2 94.4 386.4 0 597.8-320.2 597.8-597.8 0-9.2-0.2-18.2-0.6-27.2 41-29.4 76.6-66.4 104.8-108.6z",
  LINKEDIN2:
    "M384 384h177.106v90.782h2.532c24.64-44.194 84.958-90.782 174.842-90.782 186.946 0 221.52 116.376 221.52 267.734v308.266h-184.61v-273.278c0-65.184-1.334-149.026-96.028-149.026-96.148 0-110.82 70.986-110.82 144.292v278.012h-184.542v-576z M64 384h192v576h-192v-576z M256 224c0 53.019-42.981 96-96 96s-96-42.981-96-96c0-53.019 42.981-96 96-96s96 42.981 96 96z",
  CHEVRON_DOWN:
    "M1024 512c0-282.77-229.23-512-512-512s-512 229.23-512 512 229.23 512 512 512 512-229.23 512-512zM96 512c0-229.75 186.25-416 416-416s416 186.25 416 416-186.25 416-416 416-416-186.25-416-416z",
  MENU_BURGER:
    "M64 192h896v192h-896zM64 448h896v192h-896zM64 704h896v192h-896z",
  SEARCH:
    "M992.262 871.396l-242.552-206.294c-25.074-22.566-51.89-32.926-73.552-31.926 57.256-67.068 91.842-154.078 91.842-249.176 0-212.078-171.922-384-384-384-212.076 0-384 171.922-384 384s171.922 384 384 384c95.098 0 182.108-34.586 249.176-91.844-1 21.662 9.36 48.478 31.926 73.552l206.294 242.552c35.322 39.246 93.022 42.554 128.22 7.356s31.892-92.898-7.354-128.22zM384 640c-141.384 0-256-114.616-256-256s114.616-256 256-256 256 114.616 256 256-114.614 256-256 256z",
  EJECT: "M0 768h1024v128h-1024zM512 128l512 512h-1024z",
  PLUS:
    "M992 384h-352v-352c0-17.672-14.328-32-32-32h-192c-17.672 0-32 14.328-32 32v352h-352c-17.672 0-32 14.328-32 32v192c0 17.672 14.328 32 32 32h352v352c0 17.672 14.328 32 32 32h192c17.672 0 32-14.328 32-32v-352h352c17.672 0 32-14.328 32-32v-192c0-17.672-14.328-32-32-32z",
  LIST_ROBOT:
    "M14.22,1.26h-9A4.12,4.12,0,0,0,1.11,5.37V9.06a4.12,4.12,0,0,0,4.11,4.11H9.94l4,3.32a.45.45,0,0,0,.75-.35v-3a4.12,4.12,0,0,0,3.66-4.09V5.37A4.12,4.12,0,0,0,14.22,1.26Zm3.2,7.8a3.21,3.21,0,0,1-3.2,3.2.45.45,0,0,0-.45.45v2.45l-3.37-2.8a.45.45,0,0,0-.29-.1H5.23A3.21,3.21,0,0,1,2,9.06V5.37a3.21,3.21,0,0,1,3.2-3.2h9a3.21,3.21,0,0,1,3.2,3.2Z",
  LIST_CHAT: `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.44 17"><defs><style>.cls-1{fill:aqua;}</style></defs><title>list_chat</title><circle class="cls-1" cx="6.22" cy="7.07" r="1.08"/><circle class="cls-1" cx="9.57" cy="7.07" r="1.08"/><circle class="cls-1" cx="12.92" cy="7.07" r="1.08"/><path class="cls-1" d="M14.22,1.26h-9A4.12,4.12,0,0,0,1.11,5.37V9.06a4.12,4.12,0,0,0,4.11,4.11H9.94l4,3.32a.45.45,0,0,0,.75-.35v-3a4.12,4.12,0,0,0,3.66-4.09V5.37A4.12,4.12,0,0,0,14.22,1.26Zm3.2,7.8a3.21,3.21,0,0,1-3.2,3.2.45.45,0,0,0-.45.45v2.45l-3.37-2.8a.45.45,0,0,0-.29-.1H5.23A3.21,3.21,0,0,1,2,9.06V5.37a3.21,3.21,0,0,1,3.2-3.2h9a3.21,3.21,0,0,1,3.2,3.2Z"/></svg>`,
  FOLLOW:
    "M755.188 64c-107.63 0-200.258 87.554-243.164 179-42.938-91.444-135.578-179-243.216-179-148.382 0-268.808 120.44-268.808 268.832 0 301.846 304.5 380.994 512.022 679.418 196.154-296.576 511.978-387.206 511.978-679.418 0-148.392-120.43-268.832-268.812-268.832z",
  UNFOLLOW:
    "M755.188 64c148.382 0 268.812 120.44 268.812 268.832 0 292.21-315.824 382.842-511.978 679.418-207.522-298.424-512.022-377.572-512.022-679.418 0-148.392 120.426-268.832 268.808-268.832 60.354 0 115.99 27.53 160.796 67.834l-77.604 124.166 224 128-128 320 352-384-224-128 61.896-92.846c35.42-21.768 75.21-35.154 117.292-35.154z",
  VOLUME_HIGH:
    "M869.18 900.43c-11.998 0-23.99-4.576-33.146-13.727-18.305-18.307-18.305-47.984 0-66.291 85.584-85.588 132.719-199.375 132.719-320.41 0-121.033-47.135-234.822-132.719-320.408-18.305-18.307-18.305-47.986 0-66.291s47.986-18.305 66.291 0c103.291 103.293 160.178 240.625 160.178 386.699s-56.887 283.406-160.178 386.699c-9.152 9.152-21.148 13.729-33.145 13.729zm-166.514-88.389c-11.998 0-23.99-4.576-33.146-13.727-18.305-18.305-18.305-47.984 0-66.291 127.936-127.936 127.936-336.104 0-464.039-18.305-18.307-18.305-47.986 0-66.291 18.305-18.303 47.986-18.305 66.291 0C815.493 281.371 859.375 387.312 859.375 500c0 112.686-43.883 218.629-123.564 298.311-9.152 9.154-21.148 13.73-33.145 13.73zm-166.514-88.389c-11.998 0-23.992-4.576-33.146-13.729-18.307-18.307-18.307-47.982 0-66.289 79.199-79.199 79.199-208.064 0-287.262-18.307-18.307-18.307-47.986 0-66.291s47.984-18.305 66.291 0c115.752 115.752 115.752 304.094 0 419.846-9.152 9.148-21.148 13.725-33.145 13.725zM406.256 937.5a31.239 31.239 0 0 1-22.104-9.154L143.306 687.5H31.249c-17.258 0-31.25-13.99-31.25-31.25v-312.5c0-17.258 13.992-31.25 31.25-31.25h112.057L384.152 71.652a31.247 31.247 0 0 1 34.057-6.773A31.248 31.248 0 0 1 437.5 93.75v812.5a31.246 31.246 0 0 1-31.244 31.25z",
  VOLUME_MID:
    "M702.666 812.041c-11.998 0-23.99-4.576-33.146-13.727-18.305-18.305-18.305-47.984 0-66.291 127.936-127.936 127.936-336.104 0-464.039-18.305-18.307-18.305-47.986 0-66.291 18.305-18.303 47.986-18.305 66.291 0C815.493 281.371 859.375 387.312 859.375 500c0 112.686-43.883 218.629-123.564 298.311-9.152 9.154-21.148 13.73-33.145 13.73zm-166.514-88.389c-11.998 0-23.99-4.576-33.146-13.729-18.307-18.307-18.307-47.982 0-66.289 79.199-79.199 79.199-208.064 0-287.262-18.307-18.307-18.307-47.986 0-66.291s47.984-18.305 66.291 0c115.752 115.752 115.752 304.094 0 419.846-9.152 9.148-21.148 13.725-33.145 13.725zM406.256 937.5a31.239 31.239 0 0 1-22.104-9.154L143.306 687.5H31.249c-17.258 0-31.25-13.99-31.25-31.25v-312.5c0-17.258 13.992-31.25 31.25-31.25h112.057L384.152 71.652a31.247 31.247 0 0 1 34.057-6.773A31.248 31.248 0 0 1 437.5 93.75v812.5a31.246 31.246 0 0 1-31.244 31.25z",
  VOLUME_LOW:
    "M536.152 723.652c-11.998 0-23.99-4.576-33.146-13.729-18.307-18.307-18.307-47.982 0-66.289 79.199-79.199 79.199-208.064 0-287.262-18.307-18.307-18.307-47.986 0-66.291s47.984-18.305 66.291 0c115.752 115.752 115.752 304.094 0 419.846-9.152 9.148-21.148 13.725-33.145 13.725zM406.256 937.5a31.239 31.239 0 0 1-22.104-9.154L143.306 687.5H31.249c-17.258 0-31.25-13.99-31.25-31.25v-312.5c0-17.258 13.992-31.25 31.25-31.25h112.057L384.152 71.652a31.247 31.247 0 0 1 34.057-6.773A31.248 31.248 0 0 1 437.5 93.75v812.5a31.246 31.246 0 0 1-31.244 31.25z",
  VOLUME_OFF:
    "M406.256 937.5c-8.133 0-16.125-3.174-22.104-9.154l-240.846-240.846h-112.057c-17.258 0-31.25-13.99-31.25-31.25v-312.5c0-17.258 13.992-31.25 31.25-31.25h112.057l240.846-240.848c8.939-8.939 22.379-11.611 34.057-6.773 11.678 4.836 19.291 16.232 19.291 28.871v812.5c0 12.641-7.613 24.035-19.291 28.871-3.865 1.602-7.928 2.379-11.953 2.379z",
  BURGER: "M64 192h896v192h-896zM64 448h896v192h-896zM64 704h896v192h-896z"
};

export default ICONS;
